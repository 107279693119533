import { Typography } from "antd";
import React from "react";

const styles = {
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: 1000,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  message: {
    color: 'white',
    fontWeight: 400,
  }
}

const Backdrop = ({ message = '' }) => {
  return (
    <div style={styles.root}>
      <Typography.Title level={2} style={styles.message}>
        {message}
      </Typography.Title>
    </div>
  );
}

export default Backdrop;