export const permissionConstants = {
  "Hazards_Approve/Close": "Hazards_Approve/Close",
  Hazards_Assign: "Hazards_Assign",
  "Hazards_Close Duplicates": "Hazards_Close Duplicates",
  "Hazards_Edit Closed Hazards": "Hazards_Edit Closed Hazards",
  "Hazards_Edit Archives": "Hazards_Edit Archives",
  "Hazards_Edit In-Progress Hazards": "Hazards_Edit In-Progress Hazards",
  Hazards_Raise: "Hazards_Raise",
  Hazards_Resolve: "Hazards_Resolve",
  Hazards_Review: "Hazards_Review",
  "Incidents_Approve/Close": "Incidents_Approve/Close",
  Incidents_Assign: "Incidents_Assign",
  "Incidents_Close Duplicates": "Incidents_Close Duplicates",
  "Incidents_Edit Archives": "Incidents_Edit Archives",
  "Incidents_Edit Incident": "Incidents_Edit In-Progress Incidents",
  Incidents_Raise: "Incidents_Raise",
  Incidents_Resolve: "Incidents_Resolve",
  "Incidents_Edit Closed Incidents": "Incidents_Edit Closed Incidents",
  "Management_Escalation_Create/Read/Edit":
    "Management_Escalation_Create/Read/Edit",
  Management_Escalation_Delete: "Management_Escalation_Delete",
  "Management_Notification Groups_Create/Read/Edit":
    "Management_Notification Groups_Create/Read/Edit",
  show_all_issues: "show_all_issues",
  show_archive: "show_archive",
  show_checklist: "show_checklist",
  show_dashboard: "show_dashboard",
  show_home: "show_home",
  show_leaderboard: "show_leaderboard",
  show_management: "show_management",
  show_my_actions: "show_my_actions",
  show_raise: "show_raise",
  "show_inspections": "show_inspections",
  show_closed: "show_closed",
  show_report: "show_report",
  show_ehs: "show_ehs",
  Incidents_Raise: "Incidents_Raise",
  "Incidents_Delete Incident Form": "Incidents_Delete Incident Form",
  incident_home_tile: "Incidents_Incident Home Page Tile",
  ehs_home_tile: "EHS Library_EHS Library Home Page Tile",
  task_home_tile: "Task_Task Home Page Tile",
  inspection_home_tile: "Inspections_Inspection Home Page Tile",
  "Issues_Delete Issues Form ": "Issues_Delete Issues Form ",
  "Issues_Issues Home Page Tile": "Issues_Issues Home Page Tile",
  "Training_Training Home Page Tile": "TrTr8001",
  "Task_All Task": "TaVi7001",
  "HSE Documentation_View Archives": "HSE Documentation_View Archives",

  "HSE Documentation_View All Documents": "HSVi1001",
  "HSE Documentation_Edit All Documents": "HSEd1002",
  "Incidents_Create an Incident Form": "InCr2001",
  "Incidents_Edit Incident Form": "InEd2002",
  Edit_Incident_Form: "InEd2002",
  "Incidents_Archive Incident Form ": "InAr2003",
  "Incidents_View All Incidents & Reports": "InVi2004",
  View_Incident_Form: "InVi2004",
  "Incidents_Re-open Closed Incidents": "InRe2005",
  Reopen_Closed_Incident_Form: "InRe2005",
  "Incidents_View Incident Analysis": "InVi2006",
  "Incidents_Extract Incident Reports": "InEx2007",
  "Incidents_Edit Report": "InEd2008",
  Edit_Incident_Report: "InEd2008",
  "Inspections_Create Template": "InCr3001",
  "Inspections_Edit Template": "InEd3002",
  "Inspections_Archive Template": "InAr3003",
  "Inspections_Schedule Inspection": "InSc3004",
  "Inspections_Edit Inspection Schedule": "InEd3005",
  "Inspections_View All Template": "InVi3006",
  "Inspections_View All Inspections & Reports": "InVi3007",
  "Inspections_View Inspection Analysis": "InVi3008",
  "Inspections_Extract Inspection Reports": "InEx3009",
  "Issues_Create an Issues Form": "IsCr4001",
  "Issues_Edit Issues Form": "IsEd4002",
  "Issues_Archive Issues Form ": "IsAr4003",
  "Issues_View All Issues & Reports": "IsVi4004",
  "Issues_Re-open Closed Issues": "IsRe4005",
  "Issues_Extract Issues Reports": "IsEx4006",
  "Issues_Edit Report": "IsEd4007",
  "Issues_View Issue Analysis": "IsVi4008",
  "Management_Facility_Create/Read/Edit": "MaFa5001",
  "Management_Users_Create/Read/Edit": "MaUs5002",
  "Management_Custom Fields_Create/Read/Edit": "MaCu5003",
  "Management_User Groups_Create/Read/Edit": "MaUs5004",
  "Management_Roles and Privileges_Create/Read/Edit": "MaRo5005",
  "Management_Branding_Create/Read/Edit": "MaBr5014",
  "Management_Roles and Privileges_Delete": "MaRo5006",
  "Management_Notifications & Escalations_Delete": "MaNo5007",
  "Management_User Groups_Delete": "MaUs5008",
  "Management_Custom Fields_Delete": "MaCu5009",
  "Management_Users_Delete": "MaUs5010",
  "Management_Facility_Delete": "MaFa5011",
  "Management_Notifications & Escalations_Create/Read/Edit": "MaNo5012",
  "Management_Set Confidentiality_Create/Read/Edit": "MaSe5013",
  "Module_HSE Documentation": "MoHS6001",
  "All_Actions": "AcAl1001",
  "Task_Edit_Task": "TaEd7003",
  "Task_Extract Task Reports": "TaEx7002",
  "Task_Dashboard": "TaVi2004",
  "View All Dashboards" : "DaVi1001",
  "HSE_Plan View All": "HsVi1003",

  "RiskAssessment_Create Template": "RiCr9001",
  "RiskAssessment_Edit Template": "RiEd9002",
  "RiskAssessment_View All Templates": "RiVi9003",
  "RiskAssessment_Schedule": "RiSc9004",
  "RiskAssessment_Edit Schedule": "RiEd9005",
  "RiskAssessment_View All Reports": "RiVi9006",
  "RiskAssessment_Edit All Reports": "RiEd9007",
  "RiskAssessment_View All Analysis": "RiVi9008",
  "RiskAssessment_Extract Reports": "RiEx9009",

  "View_HSE_Metrics":	"ViHs1101",
  "Add_Edit_HSE_Metrics":	"AdHs1102",

  "Create_Read_Edit_Approvers": "MaAp5015"
};

export const MAX_ALLOWED_FILE_UPLOAD_LIMIT = 50; //MB
export const MAX_ALLOWED_CHARS_IN_HAZARD_PDF_RA_ROW = 1200;
export const MAX_ALLOWED_FIELD_IN_RA_HAZARDS_SECTION = 10;
