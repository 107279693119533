import { getSearchParamsUrlStr } from "@iso/utils/helpers";
import request from "./api";

const customFieldApi = {
  getHazardField: (id) => request.get(`/cf/hazards/${id}`),
  getAllHazardField: () => request.get(`/cf/hazards`),
  getHazardStatus: () => request.get("/cf/hazard/status"),
  getIncidentField: (id) => request.get(`/cf/incidents/${id}`),
  getTaskField: (id) => request.get(`/cf/tasks/${id}`),
  getAllTaskField: () => request.get(`/cf/tasks`),
  getAllIncidentField: (id) => request.get(`/cf/incidents`),
  getUserRolesField: () => request.get(`/cf/roles/`),
  getUserAllRolesField: () => request.get(`/cf/roles`),
  createField: (data) => request.post("/cf", data),
  deleteField: (id, name) => request.delete(`/cf/${name}/${id}`),
  getRiskMapField: () => request.get("/cf/risklevelmap"),
  createRiskField: (data) => request.post("/cf/risklevelmap", data),
  fetchCFHazardImport: () =>
    request.get("/cf/hazard/import/configured/facility"),
  fetchCFIncidentImport: () =>
    request.get("/cf/incident/import/configured/facility"),
  fetchCFTaskImport: () =>
    request.get("/cf/task/import/configured/facility"),
  fetchCFUserRolesImport: () =>
    request.get("/cf/role/import/configured/facility"),
  putCFHazardImport: (sourceFacility, targetFacility) =>
    request.put(`/cf/hazard/import/${sourceFacility}/${targetFacility}`),
  putCFIncidentImport: (sourceFacility, targetFacility) =>
    request.put(`/cf/incident/import/${sourceFacility}/${targetFacility}`),
  putCFTaskImport: (sourceFacility, targetFacility) =>
    request.put(`/cf/task/import/${sourceFacility}/${targetFacility}`),
  putCFRoleImport: (sourceFacility, targetFacility) =>
    request.put(`/cf/role/import/${sourceFacility}/${targetFacility}`),
  getCategoriesField: () => request.get('/cf/inspection'),
  getRiskAssessmentField: () => request.get('/cf/riskassessment'),
  getHSEFileType: () => request.get('/cf/hse/file/type'),
  importFacilityConfig: (data) => request.put(`/cf/task/import/${data.sourceFacility}/${data.targetFacility}`)
};

export default customFieldApi;
