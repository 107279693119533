import React, { useContext } from 'react';
import { CustomLocalTranslationContext } from '@iso/context/CustomLocalTranslationContext';
import { get } from 'lodash';

const replaceTokens = (str, translations, toLowerCase) => {
  return str.replace(/\{([^}]+)\}/g, (_, tokenName) => {
    const tokenValue = translations[tokenName];
    const finalValue = tokenValue !== undefined ? tokenValue : tokenName;
    
    return toLowerCase ? finalValue.toLowerCase() : finalValue;
  });
};

const useCustomLocalTranslation = () => {
  const { translations } = useContext(CustomLocalTranslationContext);

  return (key, containsToken, toLowerCase = false) => {
    if (containsToken) return replaceTokens(key, translations, toLowerCase);

    const translation = get(translations, key) || get(translations, key.toLowerCase());

    return translation || key;
  }
}

export default useCustomLocalTranslation;