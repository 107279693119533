import { facilityManagementApi, resolveRequest } from '@iso/config/api';
import { isCurrentRoutePublic } from '@iso/route.constants';
import React, { createContext, useEffect, useState } from 'react';

export const OhlDefinitionDataContext = createContext({});

export const OhlDefinitionDataProvider = ({ children }) => {
  const [isFetchingOhlNodeDefinition, setIsFetchingOhlNodeDefinition] = useState(false);
  const [noOfActiveOrgLevels, setNoOfActiveOrgLevels] = useState(1);
  const [activeOrgLevelData, setActiveOrgLevelData] = useState([]);
  const [orgLevelDataMap, setOrgLevelDataMap] = useState([]);
  const [orgDefinitionData, setOrgDefinitionData] = useState({});

  const fetchDefinitions = async () => {
    setIsFetchingOhlNodeDefinition(true);

    const promiseLevel = facilityManagementApi.getLevels();
    const promiseDefinition = facilityManagementApi.getDefinition();

    const [
      { value: { data, error } }, 
      { value: { data: data1, error: error1 } }
    ] = await Promise.allSettled([
      resolveRequest(promiseLevel, !isCurrentRoutePublic()),
      resolveRequest(promiseDefinition)
    ]);

    setIsFetchingOhlNodeDefinition(false);

    if (error || error1 || !Array.isArray(data)) return;

    setOrgDefinitionData(data1);
    setNoOfActiveOrgLevels(data.reduce((no, d) => no = no + (d.isActive ? 1 : 0), 0));
    setActiveOrgLevelData(data.filter(d => d.isActive).sort((a, b) => a.order - b.order));
    setOrgLevelDataMap(data.reduce((m, d) => {
      m[d.key] = d;
      return m;
    }, {}));
  }

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) fetchDefinitions();
  }, [])

  return (
    <OhlDefinitionDataContext.Provider value={{
      isFetchingOhlNodeDefinition,
      fetchOhlDefinitionData: fetchDefinitions,
      noOfActiveOrgLevels,
      activeOrgLevelData,
      orgLevelDataMap,
      orgDefinitionData
    }}>
      {children}
    </OhlDefinitionDataContext.Provider>
  );
}