import React from "react";
import { notification } from 'antd';
import successNotifyIcon from "@iso/assets/images/Notifications_Tick.svg";
import warningNotifyIcon from "@iso/assets/images/Notifications_Warning.svg";
import crossNotifyIcon from "@iso/assets/images/Notifications_Cross.svg";

const showNotification = (type, message = '', description = '') => {
  const getIcon = () => {
    switch (type) {
      case "success":
        return successNotifyIcon;
      case "error":
        return crossNotifyIcon;
      case "warning":
      case "info":
        return warningNotifyIcon;
      default:
        return successNotifyIcon;
    }
  }

  notification.open({
    message: (
      <div className="flex flex-middle">
        <img crossorigin="anonymous" src={getIcon()} alt="success-notify" />
        <div className="flex flex-column ml-12">
          <span className="title">{message}</span>
          {description && <span className="description">{description}</span>}
        </div>
      </div>
    ),
    className: "custom-antd-notification",
    closeIcon: <span />
  })
}

export default showNotification
