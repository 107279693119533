import { getSearchParamsUrlStr } from "@iso/utils/helpers";
import request from "./api";
import requestV2 from "./apiv2";

const notificationEscalationApi = {
  fetchHazardNC: () => request.get("/nm/hazard/notification/config"),
  createHazardNC: (data) =>
    request.post("/nm/hazard/notification/config", data),
  updateHazardNC: (data) =>
    request.patch("/nm/hazard/notification/config/update", data),
  deleteHazardNC: (id) =>
    request.delete(`/nm/hazard/notification/config/${id}`),
  fetchIncidentNC: () => request.get("/nm/incident/notification/config"),
  createIncidentNC: (data) =>
    request.post("/nm/incident/notification/config", data),
  updateIncidentNC: (data) =>
    request.patch("/nm/incident/notification/config/update", data),
  deleteIncidentNC: (id) =>
    request.delete(`/nm/incident/notification/config/${id}`),
  fetchChecklistNC: () => request.get("/nm/checklist/notification/config"),
  createChecklistNC: (data) =>
    request.post("/nm/checklist/notification/config", data),
  updateChecklistNC: (data) =>
    request.patch("/nm/checklist/notification/config/update", data),
  deleteChecklistNC: (id) =>
    request.delete(`/nm/checklist/notification/config/${id}`),
  fetchTaskNC: () => request.get("/nm/task/notification/config"),
  createTaskNC: (data) =>
    request.post("/nm/task/notification/config", data),
  updateTaskNC: (data) =>
    request.patch("/nm/task/notification/config/update", data),
  deleteTaskNC: (id) =>
    request.delete(`/nm/task/notification/config/${id}`),
  fetchHazardESC: () => request.get("/nm/hazard"),
  createHazardESC: (data) => request.post("/nm/hazard/sla", data),
  updateHazardESC: (data) => request.patch("/nm/hazard/sla/update", data),
  deleteHazardESC: (id) => request.delete(`/nm/hazard/${id}`),
  fetchIncidentESC: () => request.get("/nm/incident"),
  createIncidentESC: (data) => request.post("/nm/incident/sla", data),
  updateIncidentESC: (data) => request.patch("/nm/incident/sla/update", data),
  deleteIncidentESC: (id) => request.delete(`/nm/incident/${id}`),
  fetchChecklistESC: () => request.get("/nm/checklist"),
  createChecklistESC: (data) => request.post("/nm/checklist/sla", data),
  updateChecklistESC: (data) => request.patch("/nm/checklist/sla/update", data),
  deleteChecklistESC: (id) => request.delete(`/nm/checklist/${id}`),
  fetchTaskESC: () => request.get("/nm/task"),
  updateTaskESC: (data) => request.patch("/nm/task/sla/update", data),
  deleteTaskESC: (id) => request.delete(`/nm/task/${id}`),
  createTaskESC: (data) => request.post("/nm/task/sla", data),
  fetchHazardImport: () => request.get("/nm/hazard/import/configured/facility"),
  fetchIncidentImport: () =>
    request.get("/nm/incident/import/configured/facility"),
  fetchChecklistImport: () =>
    request.get("/nm/checklist/import/configured/facility"),
  fetchTaskImport: () =>
    request.get("/nm/task/import/configured/facility"),
  getNotifcationMetadata: () => request.get("/nm"),
  putHazardImport: (sourceFacility, targetFacility) =>
    request.put(`/nm/hazard/import/${sourceFacility}/${targetFacility}`),
  putIncidentImport: (sourceFacility, targetFacility) =>
    request.put(`/nm/incident/import/${sourceFacility}/${targetFacility}`),
  putChecklistImport: (sourceFacility, targetFacility) =>
    request.put(`/nm/checklist/import/${sourceFacility}/${targetFacility}`),
  putTaskImport: (sourceFacility, targetFacility) =>
    request.put(`/nm/task/import/${sourceFacility}/${targetFacility}`),
  fetchNotifiers: (params, type) => request.get(`/nm/${type || 'incident'}/notifier`, params),

  //v2
  fetchIssuesNotificationConfig: (facilityId) => requestV2.get(`/nm/issues/config/${facilityId}`),
  fetchIssuesEscalations: (facilityId) => requestV2.get(`/nm/issues/escalation/${facilityId}`),
  createIssuesNotificationConfig: (data) => requestV2.post(`/nm/issues/config`, data),
  createIssuesEscalations: (data) => requestV2.post(`/nm/issues/escalation`, data),
  deleteIssuesNotificationConfig: (id) => requestV2.delete(`/nm/issues/config/${id}`),
  deleteIssuesEscalations: (id) => requestV2.delete(`/nm/issues/escalation/${id}`),

  fetchInspectionsNotificationConfig: (facilityId) => requestV2.get(`/nm/inspection/config/${facilityId}`),
  fetchInspectionsEscalations: (facilityId) => requestV2.get(`/nm/inspection/escalation/${facilityId}`),
  createInspectionsNotificationConfig: (data) => requestV2.post(`/nm/inspection/config`, data),
  createInspectionsEscalations: (data) => requestV2.post(`/nm/inspection/escalation`, data),
  deleteInspectionsNotificationConfig: (id) => requestV2.delete(`/nm/inspection/config/${id}`),
  deleteInspectionsEscalations: (id) => requestV2.delete(`/nm/inspection/escalation/${id}`),

  fetchTasksNotificationConfig: (facilityId) => requestV2.get(`/nm/task/config/${facilityId}`),
  fetchTasksEscalations: (facilityId) => requestV2.get(`/nm/task/escalation/${facilityId}`),
  createTasksNotificationConfig: (data) => requestV2.post(`/nm/task/config`, data),
  createTasksEscalations: (data) => requestV2.post(`/nm/task/escalation`, data),
  deleteTasksNotificationConfig: (id) => requestV2.delete(`/nm/task/config/${id}`),
  deleteTasksEscalations: (id) => requestV2.delete(`/nm/task/escalation/${id}`),

  fetchHSENotificationConfig: () => requestV2.get(`/nm/hsed/config`),
  fetchHSEEscalations: () => requestV2.get(`/nm/hsed/escalation`),
  createHSENotificationConfig: (data) => requestV2.post(`/nm/hsed/config`, data),
  createHSEEscalations: (data) => requestV2.post(`/nm/hsed/escalation`, data),
  deleteHSENotificationConfig: (id) => requestV2.delete(`/nm/hsed/config/${id}`),
  deleteHSEEscalations: (id) => requestV2.delete(`/nm/hsed/config/${id}`),

  importIssueFacilityConfig: (data) => requestV2.put(getSearchParamsUrlStr(`/nm/issues/import`, data)),
  importInspectionFacilityConfig: (data) => requestV2.put(getSearchParamsUrlStr(`/nm/inspection/import`, data)),
  importTaskFacilityConfig: (data) => requestV2.put(getSearchParamsUrlStr(`/nm/task/import`, data)),

  fetchRiskAssessmentsNotificationConfig: (facilityId) => requestV2.get(`/nm/ra/config/${facilityId}`),
  fetchRiskAssessmentsEscalations: (facilityId) => requestV2.get(`/nm/ra/escalation/${facilityId}`),
  createRiskAssessmentsNotificationConfig: (data) => requestV2.post(`/nm/ra/config`, data),
  createRiskAssessmentsEscalations: (data) => requestV2.post(`/nm/ra/escalation`, data),
  deleteRiskAssessmentsNotificationConfig: (id) => requestV2.delete(`/nm/ra/config/${id}`),
  deleteRiskAssessmentsEscalations: (id) => requestV2.delete(`/nm/ra/escalation/${id}`),
};

export default notificationEscalationApi;
