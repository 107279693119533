import { resolveRequestUM, userAuthApi } from "@apiUM";
import jwtDecode from "jwt-decode";
import i18n from "@iso/i18n";
import { setLanguageTimezone } from "@iso/utils/helpers";

let response;

const fetchNewToken = async () => {
  const azureToken =  localStorage.getItem("azureToken");
  if (!azureToken || azureToken === 'undefined') return;
  const promise = userAuthApi.getAuth(azureToken);
  response = await resolveRequestUM(promise);
  if (response.error) {
    //show notification
  } else if (response.statusCode === 200) {
     localStorage.setItem("token", response.data);
     localStorage.setItem("loggedIN", true);
     localStorage.setItem("tokenExpiry", jwtDecode(response.data)["exp"]);
     setLanguageTimezone(response.data, i18n);
  }
};

export default fetchNewToken;
