import request from "./api";

const workHoursApi = {
  getWorkHours: (facilityId, startDate, endDate) =>
    request.get(`/manhours/${facilityId}/${startDate}/${endDate}`),
  putWorkHours: (logId, hours, departmentId) =>
    request.put(`/manhours/${logId}/${hours}/${departmentId}`),
  putBulkWorkHours: (data) => request.post(`/manhours/upload`, data),

  patchWorkHours: (data) => request.patch("/manhours/update", data),
};

export default workHoursApi;
